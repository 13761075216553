<template>
  <div class="faq container">
    <a href="/">
      <b-img
        :src="logoImage"
        alt="logo"
        class="mb-5 d-block"
        style="width: 55px"
      />
    </a>

    <b-button
      href="/"
      variant="primary"
      size="sm"
      class="mb-2"
    >
      Back to portal
    </b-button>
    <h1>Frequently asked questions</h1>

    <h2>Authentication</h2>

    <h3>I forgot my password. How can I reset it?</h3>
    <p>
      Visit the following page to reset your password: <a
        :href="resetPasswordUrl"
        target="_blank"
      >Reset your password</a>
    </p>

    <h3>My login is protected by Two-Factor authentication but I no longer have access to my authenticator app anymore.</h3>
    <p>
      Please contact your organization's administrator. He can reset your Two-Factor token. If you don't know who the administrator is, please contact <a href="mailto:portal@mentiq.com">portal@mentiq.com</a>.
    </p>

    <h3>I get an unknown error when I try to log in with Two-Factor authentication.</h3>
    <p>
      Please reset or clear your browser's cache and cookies. For browser-specific instructions, click
      <a
        href="https://it.umn.edu/services-technologies/how-tos/reset-or-clear-browser-cache-cookies"
        target="_blank"
        rel="help noopener noreferrer"
      >here</a>.
      <br>If you are still having trouble signing in after clearing your browser cache and cookies, please contact <a href="mailto:portal@mentiq.com">portal@mentiq.com</a>.
    </p>

    <h2>Assets</h2>
    <h3>I cannot see any assets or a specific asset I was trying to see.</h3>
    <p>You may not have enough security permissions to see some assets. Your organization administrator can change your security role accordingly.</p>

    <h3>I am an administrator of my organization but I cannot see some assets that I intented to see.</h3>
    <p>Please contact <a href="mailto:portal@mentiq.com">portal@mentiq.com</a>.</p>

    <h2>General</h2>
    <h3>The webpage displays an unexpected error page.</h3>
    <p>
      Please note the time the error occurred and send an email to <a href="mailto:portal@mentiq.com">portal@mentiq.com</a>.
    </p>

  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { BImg, BButton } from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
import useVerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/useVerticalNavMenu'
import { $themeConfig } from '@themeConfig'
import config from '@/config'

export default {
  components: {
    BImg,
    BButton,
  },
  setup(props) {
    const { skin } = useAppConfig()

    const {
      isVerticalMenuCollapsed,
    } = useVerticalNavMenu(props)

    // App Name
    const {
      appLogoImage, appLogoImageDark, appLogoImageCollapsed, appLogoImageDarkCollapsed,
    } = $themeConfig.app

    const logoImage = computed(() => {
      if (skin.value === 'dark') {
        if (isVerticalMenuCollapsed) {
          return appLogoImageDarkCollapsed
        }

        return appLogoImageDark
      }

      if (isVerticalMenuCollapsed) {
        return appLogoImageCollapsed
      }

      return appLogoImage
    })

    return {
      logoImage,
    }
  },
  computed: {
    resetPasswordUrl() {
      return `${config.oidcAuthority}/Account/ForgotPassword`
    },
  },
}

</script>

<style scoped>
.faq {
    margin-top: 50px;
}

h2 {
    margin-top: 50px;
    font-weight: 700;
}

h3 {
    margin-top: 20px;
}
</style>
